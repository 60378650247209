import { h, FunctionComponent } from 'preact'
import { Typography } from 'antd'
import { Logo } from './logo'
import style from './style.module.css'

const { Title } = Typography

const currentURL = new URL(window.document.location.href)
// dm devportal embed the ad tester in the page, in this case we need to remove the header.
// longterm solution should be to stop embed in iframe and put as link and open in new window
const embeded = currentURL.searchParams.get('embeded') === '1'

const Header: FunctionComponent = () =>
  !embeded ? (
    <header class={style.topBar}>
      <div class={style.topBarContent}>
        <div className={style.logo}>
          <Logo />
        </div>
        <Title id={style.title} level={1}>
          Ad Tester
        </Title>
      </div>
    </header>
  ) : (
    null
  )

export default Header
