import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import frenchTranslation from './translations/fr.json'
import englishTranslation from './translations/en.json'

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: englishTranslation,
      },
      fr: {
        translation: frenchTranslation,
      },
    },
  })

export default i18n
