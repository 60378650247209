import { h, createContext, FunctionComponent, VNode, ComponentChildren } from 'preact'
import { useContext, useReducer } from 'preact/hooks'
import { actions, ActionTypes, SettingsContextAction } from './actions'
import { SettingsContextState } from './state'

export const SettingsContext = createContext<SettingsContextState | null>(null)
export const SettingDispatch = createContext<ReturnType<typeof actions> | null>(null)

export const SettingsProvider: FunctionComponent<{ children: ComponentChildren }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, getInitialState())

  const action = actions(dispatch)

  return (
    <SettingsContext.Provider value={state}>
      <SettingDispatch.Provider value={action}> {children} </SettingDispatch.Provider>
    </SettingsContext.Provider>
  )
}

//initial State
export const getInitialState = (): SettingsContextState => {
  const defaultPositionAttribute = {
    checked: false,
    adTagEnabled: false,
    adTag: null,
    isDisabled: false,
  }
  return {
    adPositions: [
      {
        id: 'preroll',
        ...defaultPositionAttribute,
        checked: true,
      },
      {
        id: 'midroll',
        ...defaultPositionAttribute,
      },
      {
        id: 'postroll',
        ...defaultPositionAttribute,
      },
      // The ad tester doesn't support Live ads for now
      // { id: 'live', name: 'Live Ads', ...defaultPositionAttribute },
    ],
    isDifferentAdTagEnabled: false,
    adTagGlobal: null,
    imaToggled: false,
    imaEnabled: false,
    imaAdType: null,
    customVmapUrlEnabled: false,
    displayApplyButton: false,
    sharedSettingsId: null,
    displayResetModal: false,
    displayResetButton: false,
    shouldApplySettings: false,
    sharedId: null,
    selectedOption: null,
  }
}

/**
 * Check if the player size should be extended.
 * It should be in the case the user want to play a non linear ad to have enough
 * space available to display it without going in fullscreen
 * @param {object} state
 * @returns {boolean} true if player size should be extended, false otherwise
 */
const shouldExtendPlayerSize = (state: SettingsContextState) => {
  return state.imaToggled && !state.isDifferentAdTagEnabled && state.imaAdType === 'nonlinear'
}

const reducer = (state: SettingsContextState, action: SettingsContextAction) => {
  switch (action.type) {
    case ActionTypes.INIT_STATE:
      return { ...state, ...action.state }
    case ActionTypes.UPDATE_STATE: {
      const newState = { ...state, ...action.state, shouldApplySettings: false }
      window.localStorage.setItem('settingState', JSON.stringify(newState))
      return newState
    }
    case ActionTypes.DISPLAY_RESET_BUTTON:
      return { ...state, displayResetButton: action.shouldDisplay }
    case ActionTypes.DISPLAY_APPLY_BUTTON:
      return {
        ...state,
        displayApplyButton: action.shouldDisplay,
        ...(!action.shouldDisplay && { shouldApplySettings: false }),
      }
    case ActionTypes.UPDATE_AD_POSITIONS:
      return { ...state, adPositions: action.adPositions, displayApplyButton: true }
    case ActionTypes.TOGGLE_CUSTOM_VMAP:
      return {
        ...state,
        customVmapUrlEnabled: !state.customVmapUrlEnabled,
        displayApplyButton: true,
      }
    case ActionTypes.TOGGLE_IMA:
      return {
        ...state,
        imaToggled: !state.imaToggled,
        displayApplyButton: true,
      }
    // We cannot relate on state.imaToggled to change the modal
    // informations since it will be trigger every time the switch button is clicked
    // this action will be trigger only when settings are applied.
    case ActionTypes.IMA_ENABLED:
      return {
        ...state,
        imaEnabled: state.imaToggled,
      }
    case ActionTypes.TOGGLE_DIFFERENT_AD_TAG:
      return {
        ...state,
        isDifferentAdTagEnabled: action.isDifferentAdTagEnabled,
        adPositions: action.adPositions,
        displayApplyButton: true,
      }
    case ActionTypes.DISPLAY_RESET_MODAL:
      return {
        ...state,
        displayResetModal: action.displayResetModal,
      }
    case ActionTypes.UPDATE_AD_TAG_GLOBAL:
      return {
        ...state,
        adTagGlobal: action.adTagGlobal,
        displayApplyButton: true,
      }
    case ActionTypes.APPLY_SETTINGS:
      return {
        ...state,
        shouldApplySettings: action.shouldApplySettings,
        shouldExtendPlayerSize: shouldExtendPlayerSize(state),
      }
    case ActionTypes.IMA_AD_TYPE:
      return {
        ...state,
        imaAdType: action.imaAdType,
      }
    case ActionTypes.UPDATE_LANGUAGE: {
      const newState = {
        ...state,
        selectedLanguage: action.selectedLanguage,
        shouldApplySettings: false,
      }
      window.localStorage.setItem('settingState', JSON.stringify(newState))
      return newState
    }
    case ActionTypes.SELECT_VAST: {
      return {
        ...state,
        selectedOption: action.selectedOption,
      }
    }

    default:
      return state
  }
}

export const useSettingsDispatch = () => useContext(SettingDispatch)
export const useSettingsState = () => useContext(SettingsContext)
