import { DefaultOptionType } from "antd/es/select"
import { SettingsContextState } from "./state"

export enum ActionTypes {
  INIT_STATE = 'INIT_STATE',
  IMA_ENABLED = 'IMA_ENABLED',
  UPDATE_STATE = 'UPDATE_STATE',
  DISPLAY_RESET_BUTTON = 'DISPLAY_RESET_BUTTON',
  DISPLAY_APPLY_BUTTON = 'DISPLAY_APPLY_BUTTON',
  UPDATE_AD_POSITIONS = 'UPDATE_AD_POSITIONS',
  TOGGLE_CUSTOM_VMAP = 'TOGGLE_CUSTOM_VMAP',
  TOGGLE_IMA = 'TOGGLE_IMA',
  TOGGLE_DIFFERENT_AD_TAG = 'TOGGLE_DIFFERENT_AD_TAG',
  DISPLAY_RESET_MODAL = 'DISPLAY_RESET_MODAL',
  UPDATE_AD_TAG_GLOBAL = 'UPDATE_AD_TAG_GLOBAL',
  APPLY_SETTINGS = 'APPLY_SETTINGS',
  IMA_AD_TYPE = 'IMA_AD_TYPE',
  UPDATE_LANGUAGE = 'UPDATE_LANGUAGE',
  SELECT_VAST = 'SELECT_VAST'
}

interface InitStateAction {
  type: ActionTypes.INIT_STATE
  state: SettingsContextState
}

interface UpdateStateAction {
  type: ActionTypes.UPDATE_STATE
  state: SettingsContextState
}

interface DisplayResetButtonAction {
  type: ActionTypes.DISPLAY_RESET_BUTTON
  shouldDisplay: boolean
}

interface DisplayApplyButtonAction {
  type: ActionTypes.DISPLAY_APPLY_BUTTON
  shouldDisplay: boolean
}

interface UpdateAdPositionsAction {
  type: ActionTypes.UPDATE_AD_POSITIONS
  adPositions: SettingsContextState['adPositions']
}

interface ToggleImaAction {
  type: ActionTypes.TOGGLE_IMA
}

interface EnableImaAction {
  type: ActionTypes.IMA_ENABLED
}

interface ToggleCustomVmapAction {
  type: ActionTypes.TOGGLE_CUSTOM_VMAP
}

interface ToggleDifferentAdTagAction {
  type: ActionTypes.TOGGLE_DIFFERENT_AD_TAG
  isDifferentAdTagEnabled: boolean
  adPositions: SettingsContextState['adPositions']
}

interface DisplayResetModalAction {
  type: ActionTypes.DISPLAY_RESET_MODAL
  displayResetModal: boolean
}

interface UpdateAdTagGlobalAction {
  type: ActionTypes.UPDATE_AD_TAG_GLOBAL
  adTagGlobal: SettingsContextState['adTagGlobal']
}

interface ShouldApplySettingsAction {
  type: ActionTypes.APPLY_SETTINGS
  shouldApplySettings: boolean
}

interface SetImaAdTypeAction {
  type: ActionTypes.IMA_AD_TYPE
  imaAdType: string
}

interface UpdateLanguageAction {
  type: ActionTypes.UPDATE_LANGUAGE
  selectedLanguage: string
}

interface SelectVastAction {
  type: ActionTypes.SELECT_VAST
  selectedOption: SettingsContextState['selectedOption']
}

export type SettingsContextAction =
  | InitStateAction
  | UpdateStateAction
  | DisplayResetButtonAction
  | DisplayApplyButtonAction
  | UpdateAdPositionsAction
  | ToggleImaAction
  | EnableImaAction
  | ToggleCustomVmapAction
  | ToggleDifferentAdTagAction
  | DisplayResetModalAction
  | UpdateAdTagGlobalAction
  | ShouldApplySettingsAction
  | SetImaAdTypeAction
  | UpdateLanguageAction
  | SelectVastAction

export const actions = (dispatch: (action: SettingsContextAction) => void) => ({
  initState: (newState: SettingsContextState) => {
    dispatch({ type: ActionTypes.INIT_STATE, state: newState })
  },
  updateState: (newState: SettingsContextState) => {
    dispatch({ type: ActionTypes.UPDATE_STATE, state: newState })
  },
  displayResetButton: (shouldDisplay: boolean) => {
    dispatch({ type: ActionTypes.DISPLAY_RESET_BUTTON, shouldDisplay })
  },
  displayApplyButton: (shouldDisplay: boolean) => {
    dispatch({
      type: ActionTypes.DISPLAY_APPLY_BUTTON,
      shouldDisplay,
    })
  },
  updateAdPosition: (newPositions: SettingsContextState['adPositions']) => {
    dispatch({ type: ActionTypes.UPDATE_AD_POSITIONS, adPositions: newPositions })
  },
  toggleIma: () => {
    dispatch({ type: ActionTypes.TOGGLE_IMA })
  },
  enableIma: () => {
    dispatch({ type: ActionTypes.IMA_ENABLED })
  },
  toggleCustomVmap: () => {
    dispatch({ type: ActionTypes.TOGGLE_CUSTOM_VMAP })
  },
  toggleDifferentAdTag: (isDifferentAdTagEnabled: boolean, adPositions: SettingsContextState['adPositions']) => {
    dispatch({ type: ActionTypes.TOGGLE_DIFFERENT_AD_TAG, isDifferentAdTagEnabled, adPositions })
  },
  displayResetModal: (displayResetModal: boolean) => {
    dispatch({ type: ActionTypes.DISPLAY_RESET_MODAL, displayResetModal })
  },
  updateAdTagGlobal: (adTagGlobal: string | null) => {
    dispatch({ type: ActionTypes.UPDATE_AD_TAG_GLOBAL, adTagGlobal })
  },
  applySettings: (shouldApplySettings: boolean) => {
    dispatch({ type: ActionTypes.APPLY_SETTINGS, shouldApplySettings })
  },
  setImaAdType: (imaAdType: string) => {
    dispatch({ type: ActionTypes.IMA_AD_TYPE, imaAdType })
  },
  updateLanguage: (selectedLanguage: string) => {
    dispatch({
      type: ActionTypes.UPDATE_LANGUAGE,
      selectedLanguage,
    })
  },
  selectVast: (selectedOption?: Omit<DefaultOptionType, "children">[] | null) => {
    dispatch({ type: ActionTypes.SELECT_VAST, selectedOption })
  },
})
