import { FunctionComponent, h } from 'preact'
import { Route, Router } from 'preact-router'
import { ConfigProvider } from 'antd'

import Header from './header'
import Home from '../routes/home'
import NotFound from '../routes/404'

const DM_BLUE_PRIMARY_THEME = {
  colorPrimary: '#539aff',
  colorPrimaryBg: 'f0fcff',
  colorPrimaryBgHover: 'f0fcff',
  colorPrimaryBorder: '#d1f4ff',
  colorPrimaryBorderHover: '#a8e8ff',
  colorPrimaryHover: '#80d9ff',
  colorPrimaryActive: '#3fa0d9',
}

const App: FunctionComponent = () => (
  <div id="app">
    <script src="https://geo.dailymotion.com/libs/player.js" />
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#232323',
          colorText: '#444',
          fontFamily: `'ABCFavorit', 'Arial', 'Helvetica', 'sans-serif'`,
          fontSizeHeading1: 24,
          fontSizeHeading2: 20,
          fontSizeHeading3: 16,
        },
        components: {
          Radio: DM_BLUE_PRIMARY_THEME,
          Switch: DM_BLUE_PRIMARY_THEME,
          Input: DM_BLUE_PRIMARY_THEME,
          Timeline: {
            padding: 3,
          },
          Card: {
            boxShadow:
              '0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),0 1px 5px 0 rgb(0 0 0 / 12%);',
            boxShadowSecondary:
              '0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),0 1px 5px 0 rgb(0 0 0 / 12%);',
            boxShadowTertiary:
              '0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),0 1px 5px 0 rgb(0 0 0 / 12%);',
          },
        },
      }}
    >
      <Header />
      <main>
        <Router>
          <Route path="/" component={Home} />
          <Route default component={NotFound} />
        </Router>
      </main>
    </ConfigProvider>
  </div>
)

export default App
