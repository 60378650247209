import { h, FunctionComponent } from 'preact'
import './style/index.css'
import App from './components/app'
import '../i18n/i18n'
import { SettingsProvider } from './context/settings'

const Index: FunctionComponent = () => (
  <SettingsProvider>
    <App/ >
  </SettingsProvider>
)

export default Index
