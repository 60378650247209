/* eslint-disable max-len */
import { FunctionComponent, h } from 'preact'
import style from './style.module.css'

export const Logo: FunctionComponent = () => {
  return (
    <svg
      className={style.brandWordmark}
      viewBox="0 0 131 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="logo-alt-text"
      role="img"
    >
      <title id="logo-alt-text">Dailymotion</title>
      <path
        d="M26.8147 19.6567H24.4244L28.955 0.32666H31.3206L26.8171 19.6567H26.8147Z"
        fill="#fff"
      />
      <path
        d="M37.9329 19.6567H29.9504L34.5133 0.32666H36.9035L32.8455 17.4932H38.4327L37.9329 19.6567Z"
        fill="#fff"
      />
      <path
        d="M44.1931 12.1819L42.4684 19.6567H40.0485L41.8003 12.1259L40.4666 0.32666H42.8866L43.5819 9.74559L48.7807 0.32666H51.4778L44.1931 12.1819Z"
        fill="#fff"
      />
      <path
        d="M97.7241 2.49014H94.2203L90.4345 19.6567H88.0145L91.8004 2.49014H87.9626L88.4921 0.32666H98.2536L97.7241 2.49014Z"
        fill="#fff"
      />
      <path
        d="M97.6456 19.6567H95.5027L99.7884 0.32666H102.151L97.8955 19.6567H97.6456Z"
        fill="#fff"
      />
      <path
        d="M113.266 5.76442L111.153 15.2491C110.866 16.5916 110.465 17.5515 109.945 18.2361C109.154 19.3057 107.857 19.827 106.103 19.827C103.21 19.827 101.552 18.565 101.52 16.4015C101.51 15.7705 101.587 15.1955 101.82 14.1552L103.933 4.6705C104.635 1.46183 106.283 0.00732422 109.149 0.00732422C112.014 0.00732422 113.506 1.32295 113.538 3.51567C113.548 4.14668 113.472 4.8045 113.264 5.76198H113.266V5.76442ZM109.04 2.0368C108.206 2.0368 107.57 2.30968 107.135 2.85785C106.778 3.2964 106.595 3.87138 106.33 4.99453L104.217 14.5328C104.086 15.1078 104.012 15.656 104.017 16.0677C104.034 17.2201 104.794 17.8487 106.1 17.8487C107.491 17.8487 108.231 17.0545 108.674 15.081L110.814 5.65235C111.022 4.71923 111.101 4.28068 111.094 3.84214C111.076 2.66295 110.344 2.03193 109.035 2.03193H109.037L109.04 2.0368Z"
        fill="#fff"
      />
      <path
        d="M53.3307 0.34668H57.3368L58.4379 11.4296C58.6359 13.5663 58.7472 15.2913 58.8907 17.6253H58.9476C59.878 15.3205 60.6698 13.5712 61.7116 11.4296L67.18 0.34668H71.243L69.0729 19.6499H66.4204L67.8036 8.12351C68.0584 6.04043 68.3405 4.2619 68.6795 2.12522L68.6226 2.09598C67.6625 4.17906 66.8163 6.0112 65.7745 8.12351L60.1898 19.6499H56.7751L55.7037 8.12351C55.5057 6.04043 55.3647 4.17906 55.2236 2.12522H55.1692C54.6917 4.17906 54.2388 6.01363 53.645 8.12351L50.5421 19.6499H47.8623L53.3307 0.34668Z"
        fill="#fff"
      />
      <path
        d="M87.0869 9.9866C85.6542 17.4004 82.5365 20 77.6198 20C73.7424 20 71.607 18.3408 71.607 14.0797C71.607 12.8055 71.7456 11.5605 72.0573 10.0134C73.5469 2.60202 76.6102 0 81.5541 0C85.4018 0 87.5669 1.65915 87.5669 5.92033C87.5669 7.19454 87.3987 8.43952 87.0894 9.9866H87.0869ZM81.2423 2.04897C78.0405 2.04897 75.8778 4.12474 74.6975 10.0694C74.3882 11.4508 74.22 12.8079 74.22 13.9164C74.22 16.7913 75.4547 17.9535 77.8994 17.9535C81.131 17.9535 83.2639 15.8777 84.4739 9.933C84.756 8.55159 84.9515 7.19454 84.9515 6.086C84.9515 3.23547 83.7168 2.04897 81.2423 2.04897Z"
        fill="#fff"
      />
      <path
        d="M8.48971 0.321777C8.03194 0.321777 4.02587 0.387559 4.02587 0.387559L0 19.6883H1.89787V19.6859C2.97919 19.6761 3.38005 19.6981 4.43662 19.6981C9.37307 19.6981 12.4983 17.4834 13.9359 10.094H13.9384C14.2477 8.55177 14.4184 7.31167 14.4184 6.04233C14.4184 1.79333 12.3547 0.321777 8.48971 0.321777ZM11.5258 10.0867C10.3109 16.0119 8.36598 17.3665 5.12203 17.3835C3.98875 17.3884 3.73141 17.3787 2.87279 17.3665L5.95343 2.47307V2.47795C6.91845 2.45602 7.1461 2.44628 8.03194 2.45602C10.5162 2.47795 12.0058 3.41594 12.0058 6.25673C12.0058 7.36039 11.8104 8.71257 11.5283 10.0891L11.5258 10.0867Z"
        fill="#fff"
      />
      <path
        d="M127.766 0.321777L123.941 17.6369L121.1 0.321777H117.102L112.754 19.664H115.268L119.091 2.05159L121.909 19.664H125.866L130.189 0.321777H127.766Z"
        fill="#fff"
      />
      <path
        d="M20.7283 0.328125L12.7755 19.6581H15.3316L16.7791 16.045L17.6971 13.8011L18.0213 12.9338H20.7035L20.6169 13.8011L20.4214 16.0475L20.1443 19.6606H22.5643L24.0638 0.328125H20.7258H20.7283ZM18.87 10.6607L21.9506 2.0823L20.9361 10.6607H18.8725H18.87Z"
        fill="#fff"
      />
    </svg>
  )
}
